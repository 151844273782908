import * as React from 'react';
import { signMessage } from '@elrondnetwork/dapp-core/utils';
import { backendUrl } from '../../../config';

const Actions = () => {
  const signLoginMessage = async () => {
    const message = 'Hokizuki login';
    const signedMessage = await signMessage({
      message: message
    });

    if (signedMessage != null) {
      window.location.href =
        backendUrl +
        '/validate-wallet?message=' +
        encodeURIComponent(JSON.stringify(signedMessage.toJSON()));
    }
  };

  React.useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    signLoginMessage();
  });

  return (
    <div className='d-flex mt-4 justify-content-center'>
      <div className='action_btn'>
        <div
          className='spinner-border text-danger'
          style={{ width: '10rem', height: '10rem' }}
        ></div>
        <div className='mt-2'>
          <strong>Signing message...</strong>
        </div>
      </div>
    </div>
  );
};

export default Actions;
